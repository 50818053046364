import React from 'react';
import { Route } from 'react-router-dom';

const AppRoute = ({ component: Component, layout: Layout, title, icon, ...rest }) => (
    <Route
        {...rest}
        render={(props) => (
            <Layout icon={icon} title={title}>
                <Component {...props} />
            </Layout>
        )}
    />
);

export default AppRoute;
