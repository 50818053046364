import axios from 'axios';
import jwt from 'utils/jwt';
import AES from 'crypto-js/aes';
// import encUtf8 from 'crypto-js/enc-utf8';

// const API_KEY = 'AIzaSyD22bSJa6989EsRhSr2TyG4RYkyh84amnc';
// let API_URL = 'https://www.googleapis.com/youtube/v3';
//
// // Mocks server data with json-server
// if (global.location.search.indexOf('mock') !== -1) {
//   API_URL = `${global.location.protocol}//${global.location.hostname}:3000`;
// }
//
// const params = {
//   key: API_KEY,
// };
//
// const requests = {
//   del: (url, config) =>
//     axios.delete(`${API_URL}${url}`, config),
//   get: (url, config) =>
//     axios.get(`${API_URL}${url}`, config),
//   put: (url, config) =>
//     axios.put(`${API_URL}${url}`, null, config),
//   post: (url, config) =>
//     axios.post(`${API_URL}${url}`, null, config)
// };
//
// // Services
//
// const Videos = {
//   search: (term, limit = 10, config = null) =>
//     requests.get(`/search`,
//       {
//         ...config,
//         params: {
//           ...params,
//           part: 'snippet',
//           q: term,
//           type: 'video',
//           maxResults: limit,
//         }
//       }
//     ),
// };
//
// export default {
//   Videos
// };

const API_URL = 'http://127.0.0.1:4000/data';

// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callApi(endpoint, schema, market) {
    const fullUrl = endpoint.indexOf(API_URL) === -1 ? API_URL + endpoint : endpoint;
    return axios
        .get(fullUrl)
        .then((response) => ({ response: { entities: { [schema]: { [market]: response.data } } } }))
        .catch((error) => ({ error: error.message }));
}
// Fetches an API response and normalizes the result JSON according to schema.
// This makes every API response have the same shape, regardless of how nested it was.
function callPoolApi({ url, algo, coin, pool, poolName, kind }) {
    // return axios.get(
    //   `http://127.0.0.1:4000/v1/proxy/get?url=${url}`,
    //   {
    //     headers: {
    //       'Access-Control-Allow-Origin': '*',
    //       Authorization: `Bearer ${jwt.token({'url': url})}`
    //     }
    //   }
    // )

    let endpoint = 'http://127.0.0.1:8090';
    if (process.env.NODE_ENV === 'production') {
        endpoint = 'https://api.altcoinminingpools.com';
    }

    url = AES.encrypt(url, 'hf438yG67ig').toString();

    return axios(
        `${endpoint}/v1/proxy/get?url=${encodeURIComponent(
            url
        )}&pool=${pool}&coin=${coin}&kind=${kind}&algo=${algo}`,
        {
            method: 'GET',
            headers: {
                Authorization: `Bearer ${jwt.token({ url: url })}`,
            },
            //withCredentials: true,
        }
    )
        .then((response) => {
            const { pools = {}, time } = response.data;

            if (!(coin in pools)) {
                pools[coin] = {};
            }
            return {
                response: {
                    entities: {
                        [algo]: {
                            [coin]: {
                                [pool]: { ...pools[coin], time, poolName },
                            },
                        },
                    },
                },
            };
        })
        .catch((error) => ({ error: error.message }));
}
//   try {
//     let data = axios.get(fullUrl);
//     // await dispatch(testExample());
// console.log('axios', data);
//     return data;
//   } catch(error) {
//     throw new Error(error);
//   }

// api services
export const fetchPool = ({ url, algo, coin, pool, poolName, kind }) =>
    callPoolApi({ url, algo, coin, pool, poolName, kind });
