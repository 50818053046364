import React, { Component } from 'react';
import PoolsChart from 'shared/components/poolsChart';
import PoolRow from 'shared/components/poolRow';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ZelCash extends Component {
    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{'ZelCash mining pools - zel'}</title>
                    <meta
                        name="description"
                        content="ZelCash mining pools stats, mining pools ZelCash list online and chart - pool name, hashrate, workers,	pools fee"
                    />
                    <meta name="keywords" content="ZelCash,mining pools,zel,equihash" />
                </Helmet>
                <div className="my-3 p-3 bg-white rounded box-shadow">
                    {/*<h6 class="border-bottom border-gray pb-2 mb-0">Votecoin pools stats</h6>*/}
                    <div className="table-responsive-md">
                        <table className="table">
                            <thead className="">
                                <tr>
                                    <th className="border-top-0" scope="col">
                                        Pool
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Hashrate
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Workers
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Fee
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Updated
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Visit
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/*                <PoolRow option={{
                  url: 'http://zel.cloudpools.net/api/stats',
                  link: 'http://zel.cloudpools.net',
                  algo: 'equihash',
                  coin: 'zelcash',
                  pool: 'cloudpools',
                  poolName: 'Cloudpools',
                  fee: '0.5'
                }}
                />*/}
                                {/*                <PoolRow option={{
                  url: 'http://zel-eu.cloudpools.net/api/stats',
                  link: 'http://zel-eu.cloudpools.net',
                  algo: 'equihash',
                  coin: 'zelcash',
                  pool: 'cloudpoolseu',
                  poolName: 'Cloudpools (EU)',
                  fee: '0.5'
                }}
                />*/}
                                <PoolRow
                                    option={{
                                        url: 'https://zel.coinblockers.com/api/stats',
                                        link: 'https://zel.coinblockers.com',
                                        algo: 'equihash',
                                        coin: 'zelcash',
                                        pool: 'coinblockers',
                                        poolName: 'Coinblockers',
                                        fee: '0.4',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://zel.nibirupool.com/api/stats',
                                        link: 'https://zel.nibirupool.com',
                                        algo: 'equihash',
                                        coin: 'zelcash',
                                        pool: 'nibirupool.com',
                                        poolName: 'Nibirupool',
                                        fee: '1',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'http://madmaxpool.hopto.org/api/stats',
                  link: 'http://madmaxpool.hopto.org',
                  algo: 'equihash',
                  coin: 'zelcash',
                  pool: 'madmax',
                  poolName: 'MadMax',
                  fee: '1'
                }}
                />*/}
                                <PoolRow
                                    option={{
                                        url: 'https://equipool.1ds.us/api/stats',
                                        link: 'https://equipool.1ds.us',
                                        algo: 'equihash',
                                        coin: 'zelcash',
                                        pool: '1dsequipool',
                                        poolName: '1ds Equipool',
                                        fee: '0.2',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url: 'http://zel.cloudpools.net/api/stats',
                                        link: 'http://zel.cloudpools.net/',
                                        algo: 'equihash',
                                        coin: 'zelcash',
                                        pool: 'cloudpools',
                                        poolName: 'Cloudpools',
                                        fee: '0.5',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url:
                                            'https://zel.lucky-mining.com.ua/?page=api&action=public&api_key=api',
                                        link: 'https://zel.lucky-mining.com.ua',
                                        algo: 'equihash',
                                        coin: 'zelcash',
                                        pool: 'luckymining',
                                        poolName: 'Lucky-Mining',
                                        fee: '0',
                                        kind: 'mpos4',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'http://www.klondikepool.net/api/stats',
                  link: 'http://www.klondikepool.net',
                  algo: 'equihash',
                  coin: 'zelcash',
                  pool: 'klondikepool',
                  poolName: 'Klondikepool',
                  fee: '0.2'
                }}
                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://equi.pickaxe.pro/api/stats',
                                        link: 'https://equi.pickaxe.pro/',
                                        algo: 'equihash',
                                        coin: 'zelcash',
                                        pool: 'pickaxe',
                                        poolName: 'Pickaxe',
                                        fee: '1',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://zpool.wfmpools.com/api/stats',
                  link: 'https://zpool.wfmpools.com',
                  algo: 'equihash',
                  coin: 'zelcash',
                  pool: 'windfall',
                  poolName: 'Windfall',
                  fee: '0.5'
                }}
                />*/}
                            </tbody>
                        </table>
                    </div>
                    <small className="d-block text-right mt-3">
                        <Link to="/addminingpool" title="Add mining pool">
                            Add mining pool
                        </Link>
                    </small>
                </div>
                <PoolsChart
                    option={{
                        algo: 'equihash',
                        coin: 'zelcash',
                        title: 'ZelCash pools chart',
                    }}
                />
            </div>
        );
    }
}

export default ZelCash;
