import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import logo from 'shared/assets/img/altcoinminingpoolssite.png';

import css from './nav.css';

class Nav extends Component {
    componentDidMount() {
        // $(function () {
        //   $('[data-toggle="tooltip"]').tooltip();
        //   $('[data-toggle="offcanvas"]').on('click', function () {
        //     $('.offcanvas-collapse').toggleClass('open')
        //   })
        // });
        $(() => {
            // hold onto the drop down menu
            let dropdownMenu;

            // and when you show it, move it to the body
            $(window).on('show.bs.dropdown', (e) => {
                // grab the menu
                dropdownMenu = $(e.target).find('.dropdown-menu');

                // detach it and append it to the body
                $('body').append(dropdownMenu.detach());

                // grab the new offset position
                const eOffset = $(e.target).offset();

                // make sure to place it where it would normally go (this could be improved)
                dropdownMenu.css({
                    display: 'block',
                    top: eOffset.top + $(e.target).outerHeight(),
                    left: eOffset.left,
                });
            });

            // and when you hide it, reattach the drop down, and hide it normally
            $(window).on('hide.bs.dropdown', (e) => {
                $(e.target).append(dropdownMenu.detach());
                dropdownMenu.hide();
            });
        });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        const brandClass = classNames('navbar-brand d-flex align-items-center', css.brand);
        return (
            <div>
                <nav className="navbar navbar-expand-md fixed-top navbar-dark bg-dark">
                    <Link className={brandClass} to="/">
                        <img alt="altcoinminingpools.com" src={logo} height="16" width="160" />
                    </Link>

                    <div className="navbar-nav flex-row ml-md-auto d-md-flex">
                        <a
                            className="nav-link cursor-default"
                            onClick={() => window.scrollTo(0, 0)}
                        >
                            <span className="oi oi-chevron-top" />
                        </a>
                    </div>

                    {/*          <button className="navbar-toggler p-0 border-0" type="button" data-toggle="offcanvas">
            <span className="navbar-toggler-icon"></span>
          </button>

          <div className="navbar-collapse offcanvas-collapse" id="navbarsExampleDefault">
            <ul className="navbar-nav mr-auto">
              <li className="nav-item active">
                <a className="nav-link" href="#">Pearpool team</a>
              </li>*/}
                    {/*              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="http://example.com" id="dropdown01" data-toggle="dropdown"
                   aria-haspopup="true" aria-expanded="false">Settings</a>
                <div className="dropdown-menu" aria-labelledby="dropdown01">
                  <a className="dropdown-item" href="#">Action</a>
                  <a className="dropdown-item" href="#">Another action</a>
                  <a className="dropdown-item" href="#">Something else here</a>
                </div>
              </li>*/}
                    {/*            </ul>
          </div>*/}
                </nav>

                <div className="nav-scroller bg-white box-shadow">
                    <ul className="nav nav-underline">
                        <li className="nav-item">
                            <p className="navbar-brand nav-title">Mining pools</p>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                aria-expanded="false"
                                aria-haspopup="true"
                                className="nav-link font-weight-bold dropdown-toggle"
                                data-toggle="dropdown"
                                href="#"
                                role="button"
                            >
                                {'etchash'}
                            </a>
                            <div className="dropdown-menu">
                                <Link
                                    className="dropdown-item"
                                    to="/etchash/etc"
                                    title="Ethereum Classic mining pools list"
                                >
                                    {'Ethereum Classic'}
                                </Link>
                            </div>
                        </li>
                        <li className="nav-item dropdown">
                            <a
                                aria-expanded="false"
                                aria-haspopup="true"
                                className="nav-link font-weight-bold dropdown-toggle"
                                data-toggle="dropdown"
                                href="#"
                                role="button"
                            >
                                {'equihash'}
                            </a>
                            <div className="dropdown-menu">
                                <Link
                                    className="dropdown-item"
                                    to="/equihash/buck"
                                    title="Buck mining pools list"
                                >
                                    {'Buck'}
                                </Link>
                                {/*                                <Link
                                    className="dropdown-item"
                                    to="/equihash/hush"
                                    title="Hush mining pools list"
                                >
                                    {'Hush'}
                                </Link>*/}
                                <Link
                                    className="dropdown-item"
                                    to="/equihash/komodo"
                                    title="Komodo mining pools list"
                                >
                                    {'Komodo'}
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/equihash/litecoinz"
                                    title="Litecoinz mining pools list"
                                >
                                    {'Litecoinz'}
                                </Link>
                                {/*                                <Link
                                    className="dropdown-item"
                                    to="/equihash/snowgem"
                                    title="SnowGem mining pools list"
                                >
                                    {'SnowGem'}
                                </Link>*/}
                                {/*                                <Link
                                    className="dropdown-item"
                                    to="/equihash/votecoin"
                                    title="Votecoin mining pools list"
                                >
                                    {'Votecoin'}
                                </Link>*/}
                                <Link
                                    className="dropdown-item"
                                    to="/equihash/zcash"
                                    title="Zcash mining pools list"
                                >
                                    {'Zcash'}
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/equihash/zclassic"
                                    title="Zclassic mining pools list"
                                >
                                    {'Zclassic'}
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/equihash/zelcash"
                                    title="Zelcash mining pools list"
                                >
                                    {'Zelcash'}
                                </Link>
                                <Link
                                    className="dropdown-item"
                                    to="/equihash/zencash"
                                    title="Zencash mining pools list"
                                >
                                    {'Zencash'}
                                </Link>
                            </div>
                        </li>
                        {/*                        <li className="nav-item dropdown">
                            <a
                                aria-expanded="false"
                                aria-haspopup="true"
                                className="nav-link font-weight-bold dropdown-toggle"
                                data-toggle="dropdown"
                                href="#"
                                role="button"
                            >
                                {'neoscrypt'}
                            </a>
                            <div className="dropdown-menu">
                                <Link
                                    className="dropdown-item"
                                    to="/neoscrypt/coin2fly"
                                    title="Coin2Fly mining pools list"
                                >
                                    {'Coin2Fly'}
                                </Link>
                            </div>
                        </li>*/}
                    </ul>
                </div>
            </div>
        );
    }
}

Nav.propTypes = {};

Nav.defaultProps = {};

export default Nav;
