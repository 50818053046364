// Your dump component
import React from 'react';

const titleRow = ({ title, icon }) => {
    return (
        <If condition={title}>
            <div className="d-flex align-items-center p-3 my-3 text-white-50 bg-gray-dark rounded box-shadow">
                <If condition={icon}>
                    <img
                        alt={title}
                        className="mr-3"
                        src={require(`shared/assets/img/coin/${icon}`)}
                        height="48"
                        width="48"
                    />
                </If>
                <div className="lh-100">
                    <h6 className="mb-0 text-white lh-100">{title}</h6>
                    <small>Mining pools stats</small>
                </div>
            </div>
        </If>
    );
};

export default titleRow;
