import { all, take, put, call, fork, select } from 'redux-saga/effects';
import { api } from 'shared/services';
import * as actions from 'shared/store/actions';

// each entity defines 3 creators { request, success, failure }
const { pool } = actions;

// resuable fetch Subroutine
// entity :  top | rate
// apiFn  : api.fetchTop | api.fetchRate | ...
// id     : market | fullName
// url    : next page url. If not provided will use pass it to apiFn
function* fetchEntity(entity, apiFn, { url, algo, coin, pool, poolName, kind }) {
    yield put(entity.request({ url, algo, coin, pool, kind }));
    const { response, error } = yield call(apiFn, { url, algo, coin, pool, poolName, kind });
    if (response) yield put(entity.success(url, response));
    else yield put(entity.failure(url, error));
}

// yeah! we can also bind Generators
export const fetchPool = fetchEntity.bind(null, pool, api.fetchPool);

// load repo unless it is cached
function* loadPool({ url, algo, coin, pool, poolName, kind }, requiredFields) {
    // const repo = yield select(getRepo, market)
    //if (!repo || requiredFields.some(key => !repo.hasOwnProperty(key)))
    yield call(fetchPool, { url, algo, coin, pool, poolName, kind });
}

// Fetches data
function* watchLoadPool() {
    while (true) {
        const { url, algo, coin, pool, poolName, kind, requiredFields = [] } = yield take(
            actions.LOAD_POOL
        );
        yield fork(loadPool, { url, algo, coin, pool, poolName, kind }, requiredFields);
    }
}

// https://github.com/redux-saga/redux-saga/tree/master/examples/real-world
export default function* root() {
    yield all([fork(watchLoadPool)]);
}
