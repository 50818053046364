import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

const Home = () => {
    return (
        <div className="position-relative overflow-hidden p-3 p-md-5 m-md-3 text-center">
            <Helmet>
                <title>{'Altcoin mining pools list - altcoinminingpools.com'}</title>
                <meta
                    name="description"
                    content="Altcoin mining pools list online with stats and chart, pool name, hashrate, workers, % fee, total network coin hashrate"
                />
                <meta name="keywords" content="mining pools list,altcoin,equihash" />
            </Helmet>
            <div className="col-md-10 mx-auto">
                <h1 className="display-5 font-weight-normal">{'Altcoin mining pools list'}</h1>
                <p className="lead font-weight-normal">
                    {'Pools with stats - hashrate, workers, % fee, link and chart'}
                </p>
                <h6 className="mb-1">{'Select coin algorithm in menu and coin name'}</h6>
                <p>Ethereum Classic, Buck, Komodo, Litecoinz, Zcash, Zclassic, Zelcash, Zencash.</p>
                <Link
                    className="btn btn-outline-secondary"
                    to="/addminingpool"
                    title="How add my mining pool to stats?"
                >
                    How add my mining pool?
                </Link>
            </div>
        </div>
    );
};

export default Home;
