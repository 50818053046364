import React, { Component } from 'react';
import PoolsChart from 'shared/components/poolsChart';
import PoolRow from 'shared/components/poolRow';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Buck extends Component {
    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{'Buck mining pools'}</title>
                    <meta
                        name="description"
                        content="Buck mining pools stats, mining pools Buck list online and chart"
                    />
                    <meta name="keywords" content="Buck,mining pools,equihash" />
                </Helmet>
                <div className="my-3 p-3 bg-white rounded box-shadow">
                    <div className="table-responsive-md">
                        <table className="table">
                            <thead className="">
                                <tr>
                                    <th className="border-top-0" scope="col">
                                        Pool
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Hashrate
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Workers
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Fee
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Updated
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Visit
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://coorp.io/public_api/stats',
                                        link: 'https://coorp.io/pool/buck',
                                        algo: 'equihash',
                                        coin: 'buck',
                                        pool: 'coorpio',
                                        poolName: 'Coorp',
                                        fee: '0.2',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'http://buck.x-i-tech.com/api/stats',
                  link: 'http://buck.x-i-tech.com',
                  algo: 'equihash',
                  coin: 'buck',
                  pool: 'xitech',
                  poolName: 'x-i-tech.com',
                  fee: '1',
                }}
                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://buckpool.site/api/stats',
                  link: 'https://buckpool.site',
                  algo: 'equihash',
                  coin: 'buck',
                  pool: 'buckpool',
                  poolName: 'BuckPool',
                  fee: '1',
                }}
                />
                <PoolRow option={{
                  url: 'https://euromining.club/api/stats',
                  link: 'https://euromining.club',
                  algo: 'equihash',
                  coin: 'buck',
                  pool: 'euromining',
                  poolName: 'EuroMining',
                  fee: '1'
                }}
                />*/}
                                <PoolRow
                                    option={{
                                        url: 'https://equipool.1ds.us/api/stats',
                                        link: 'https://equipool.1ds.us',
                                        algo: 'equihash',
                                        coin: 'buck',
                                        pool: '1dsequipool',
                                        poolName: '1ds Equipool',
                                        fee: '0.2',
                                    }}
                                />
                                {/*                <PoolRow option={{
                  url: 'https://miningasia.site/api/stats',
                  link: 'https://miningasia.site',
                  algo: 'equihash',
                  coin: 'buck',
                  pool: 'miningasia',
                  poolName: 'MiningAsia',
                  fee: '1'
                }}
                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://syspool.org/api/stats',
                  link: 'https://syspool.org',
                  algo: 'equihash',
                  coin: 'buck',
                  pool: 'syspool',
                  poolName: 'Syspool',
                  fee: '0.5'
                }}
                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'http://www.klondikepool.net/api/stats',
                                        link: 'http://www.klondikepool.net',
                                        algo: 'equihash',
                                        coin: 'buck',
                                        pool: 'klondikepool',
                                        poolName: 'Klondikepool',
                                        fee: '0.2',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://fastblocks.net/api/stats',
                                        link: 'https://fastblocks.net',
                                        algo: 'equihash',
                                        coin: 'buck',
                                        pool: 'fastblocks',
                                        poolName: 'FastBlocks',
                                        fee: '1',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'http://pcmining.xyz:8080/api/stats',
                                        link: 'http://pcmining.xyz',
                                        algo: 'equihash',
                                        coin: 'buck',
                                        pool: 'pcminingxyz',
                                        poolName: 'PCMiNing',
                                        fee: '1',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url:
                                            'https://buck.lucky-mining.com.ua/?page=api&action=public&api_key=api',
                                        link: 'https://buck.lucky-mining.com.ua',
                                        algo: 'equihash',
                                        coin: 'buck',
                                        pool: 'luckymining',
                                        poolName: 'Lucky-Mining',
                                        fee: '0.15',
                                        kind: 'mpos4',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://equigems.online/api/stats',
                                        link: 'https://equigems.online',
                                        algo: 'equihash',
                                        coin: 'buck',
                                        pool: 'equigems',
                                        poolName: 'EquiGems',
                                        fee: '0.9',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://pool.whibbit.cn/api/stats',
                  link: 'https://pool.whibbit.cn',
                  algo: 'equihash',
                  coin: 'buck',
                  pool: 'whibbitcn',
                  poolName: 'Whibbit',
                  fee: '0'
                }}
                />*/}
                            </tbody>
                        </table>
                    </div>
                    <small className="d-block text-right mt-3">
                        <Link to="/addminingpool" title="Add mining pool">
                            Add mining pool
                        </Link>
                    </small>
                </div>
                <PoolsChart
                    option={{
                        algo: 'equihash',
                        coin: 'buck',
                        title: 'Buck pools chart',
                    }}
                />
            </div>
        );
    }
}

export default Buck;
