import React, { Component } from 'react';
// import PoolsChart from 'shared/components/poolsChart';
import PoolRow from 'shared/components/poolRow';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';

class Zclassic extends Component {
    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{'Ethereum classic mining pools - ETC'}</title>
                    <meta
                        name="description"
                        content="Ethereum classic mining pools stats, mining pools ETC list online and chart"
                    />
                    <meta name="keywords" content="Ethereum classic,mining pools,etc,etchash" />
                </Helmet>
                <div className="my-3 p-3 bg-white rounded box-shadow">
                    <div className="table-responsive-md">
                        <table className="table">
                            <thead className="">
                                <tr>
                                    <th className="border-top-0" scope="col">
                                        Pool
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Hashrate
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Workers
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Fee
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Updated
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Visit
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <PoolRow
                                    option={{
                                        url: 'https://etc.pearpool.com/api/stats',
                                        link: 'https://etc.pearpool.com',
                                        algo: 'etchash',
                                        coin: 'etc',
                                        pool: 'pearpool',
                                        poolName: 'Pearpool',
                                        fee: '0.5',
                                        kind: 'etcpool',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://etc.2miners.com/api/stats',
                                        link: 'https://etc.2miners.com',
                                        algo: 'etchash',
                                        coin: 'etc',
                                        pool: '2miners',
                                        poolName: '2miners',
                                        fee: '1',
                                        kind: 'etcpool',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://solo-etc.2miners.com/api/stats',
                                        link: 'https://solo-etc.2miners.com',
                                        algo: 'etchash',
                                        coin: 'etc',
                                        pool: '2minerssolo',
                                        poolName: '2miners [SOLO]',
                                        fee: '1.5',
                                        kind: 'etcpool',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://etc.solopool.org/api/stats',
                                        link: 'https://etc.solopool.org',
                                        algo: 'etchash',
                                        coin: 'etc',
                                        pool: 'solopool',
                                        poolName: 'Solopool [SOLO]',
                                        fee: '1.5',
                                        kind: 'etcpool',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://etc.crazypool.org/api/stats',
                                        link: 'https://etc.crazypool.org',
                                        algo: 'etchash',
                                        coin: 'etc',
                                        pool: 'crazypool',
                                        poolName: 'Crazypool',
                                        fee: '1',
                                        kind: 'etcpool',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://etc.digipools.org/api/stats',
                                        link: 'https://etc.digipools.org',
                                        algo: 'etchash',
                                        coin: 'etc',
                                        pool: 'digipools',
                                        poolName: 'Digipools',
                                        fee: '0.5',
                                        kind: 'etcpool',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://etc365.org/api/stats',
                                        link: 'https://etc365.org',
                                        algo: 'etchash',
                                        coin: 'etc',
                                        pool: 'etc356',
                                        poolName: 'Etc365',
                                        fee: '1',
                                        kind: 'etcpool',
                                    }}
                                />
                            </tbody>
                        </table>
                    </div>
                    <small className="d-block text-right mt-3">
                        <Link to="/addminingpool" title="Add mining pool">
                            Add mining pool
                        </Link>
                    </small>
                </div>
                {/*                <PoolsChart
                    option={{
                        algo: 'etchash',
                        coin: 'etc',
                        title: 'Ethereum classic pools chart',
                    }}
                />*/}
            </div>
        );
    }
}

export default Zclassic;
