const REQUEST = 'REQUEST';
const SUCCESS = 'SUCCESS';
const FAILURE = 'FAILURE';

function createRequestTypes(base) {
    return [REQUEST, SUCCESS, FAILURE].reduce((acc, type) => {
        acc[type] = `${base}_${type}`;
        return acc;
    }, {});
}

export const POOL = createRequestTypes('POOL');

export const LOAD_POOL = 'LOAD_POOL';

function action(type, payload = {}) {
    return { type, ...payload };
}

export const pool = {
    request: ({ url, algo, coin, pool, poolName, kind }) =>
        action(POOL[REQUEST], { url, algo, coin, pool, poolName, kind }),
    success: (url, response) => action(POOL[SUCCESS], { url, response }),
    failure: (url, error) => action(POOL[FAILURE], { url, error }),
};

export const loadPool = ({ url, algo, coin, pool, poolName, kind }, requiredFields = []) =>
    action(LOAD_POOL, { url, algo, coin, pool, poolName, kind, requiredFields });
