import React from 'react';
import NavComponent from 'components/nav';
import TitleRow from 'components/titleRow';
import nexo from 'shared/assets/ref/nexo.jpg';

const Layout = (props) => (
    <div>
        <NavComponent />
        <main className="container" role="main">
            <TitleRow icon={props.icon} title={props.title} />
            {props.children}
            <div className="position-relative text-center">
                <div className="col-md-10 mx-auto">
                    <a
                        href="https://nexo.io/ref/76zq50vfyz?src=web-link"
                        title="Nexo earn $25 in BTC"
                        target="_blank"
                    >
                        <img
                            className="w-100"
                            style={{ maxWidth: '600px' }}
                            src={nexo}
                            alt="Nexo earn $25 in BTC referal bonus image"
                        />
                    </a>
                </div>
            </div>
        </main>
        <footer
            className="footer"
            style={{
                position: 'absolute',
                bottom: 0,
                width: '100%',
                minHeight: '60px',
                paddingTop: '16px',
                backgroundColor: '#f5f5f5',
            }}
        >
            <div className="container">
                <span className="text-muted">
                    © 2018{' '}
                    <a href="http://pearpool.com" rel="noopener" target="_blank">
                        Pearpool
                    </a>
                    ,{' '}
                    <a href="https://discord.gg/TJvAuPJ" rel="noopener" target="_blank">
                        Discord channel
                    </a>{' '}
                    - contact VKPM (id 8509)
                </span>
                <p className="float-right my-0">
                    <a
                        href="https://twitter.com/pearminingpool?ref_src=twsrc%5Etfw"
                        className="twitter-follow-button"
                        data-show-count="false"
                    >
                        Follow @pearminingpool
                    </a>
                </p>
            </div>
        </footer>
    </div>
);

export default Layout;
