import React, { Component } from 'react';
import PoolsChart from 'shared/components/poolsChart';
import PoolRow from 'shared/components/poolRow';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Zcash extends Component {
    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{'Zcash mining pools - zec'}</title>
                    <meta
                        name="description"
                        content="Zcash mining pools stats, mining pools Zcash list online and chart"
                    />
                    <meta name="keywords" content="Zcash,mining pools,zec,equihash" />
                </Helmet>
                <div className="my-3 p-3 bg-white rounded box-shadow">
                    {/*<h6 class="border-bottom border-gray pb-2 mb-0">Votecoin pools stats</h6>*/}
                    <div className="table-responsive-md">
                        <table className="table">
                            <thead className="">
                                <tr>
                                    <th className="border-top-0" scope="col">
                                        Pool
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Hashrate
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Workers
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Fee
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Updated
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Visit
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <PoolRow
                                    option={{
                                        url: 'https://api-zcash.flypool.org/poolStats',
                                        link: 'https://zcash.flypool.org',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: 'flypool',
                                        poolName: 'Flypool',
                                        fee: '1',
                                        kind: 'poolStats',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url:
                                            'https://zcash.miningpoolhub.com/index.php?page=api&action=getpoolstatus&api_key=d836b8d553a54c11f70f8a65482712c281b5993d4ba12f46aeb0daa973bcfd33',
                                        link: 'https://zcash.miningpoolhub.com',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: 'miningpoolhub',
                                        poolName: 'Mining pool hub',
                                        fee: '0.9',
                                        kind: 'mpos3',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://api.nanopool.org/v1/zec/pool/',
                                        link: 'https://zec.nanopool.org',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: 'nanopool',
                                        poolName: 'Nanopool',
                                        fee: '1',
                                        kind: 'nanopool',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url:
                                            'https://zec.suprnova.cc/index.php?page=api&action=getdashboarddata&api_key=t1Ls3gJALtS8XEsogZ2GEcTyK5biiPJjxDo',
                                        link: 'https://zec.suprnova.cc',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: 'suprnova',
                                        poolName: 'Suprnova',
                                        fee: '1',
                                        kind: 'getdashboarddata',
                                    }}
                                />*/}
                                <PoolRow
                                    option={{
                                        url:
                                            'https://www2.coinmine.pl/zec/index.php?page=api&action=public',
                                        link: 'https://www2.coinmine.pl/zec',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: 'coinmine',
                                        poolName: 'Coinmine',
                                        fee: '1',
                                        kind: 'mpos2',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://luckpool.org/api/stats',
                                        link: 'https://luckpool.org',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: 'luckpool',
                                        poolName: 'LuckPool',
                                        fee: '0.5',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'http://zec.pool.sexy/api/stats',
                                        link: 'http://zec.pool.sexy',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: 'poolsexy',
                                        poolName: 'Pool Sexy',
                                        fee: '0.1',
                                        kind: 'simple2',
                                    }}
                                />*/}
                                <PoolRow
                                    option={{
                                        url: 'https://zec.2miners.com/api/stats',
                                        link: 'https://zec.2miners.com',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: '2Minerscom',
                                        poolName: '2Miners.com',
                                        fee: '1',
                                        kind: 'simple2',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://solo-zec.2miners.com/api/stats',
                                        link: 'https://solo-zec.2miners.com',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: '2Minerscomsolo',
                                        poolName: '2Miners.com Solo',
                                        fee: '1.5',
                                        kind: 'simple2',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://zecmine.pro/api/stats',
                                        link: 'https://zecmine.pro',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: 'zecminepro',
                                        poolName: 'Zecmine',
                                        fee: '0',
                                        kind: 'simple2',
                                    }}
                                />*/}
                                <PoolRow
                                    option={{
                                        url: 'https://equipool.1ds.us/api/stats',
                                        link: 'https://equipool.1ds.us',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: '1dsequipool',
                                        poolName: '1ds Equipool',
                                        fee: '0.2',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'http://zec.altpool.pro/api/stats',
                                        link: 'http://zec.altpool.pro',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: 'altpool',
                                        poolName: 'Altpool',
                                        fee: '0.5',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://equigems.online/api/stats',
                                        link: 'https://equigems.online',
                                        algo: 'equihash',
                                        coin: 'zcash',
                                        pool: 'equigems',
                                        poolName: 'EquiGems',
                                        fee: '0.9',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://zpool.wfmpools.com/api/stats',
                  link: 'https://zpool.wfmpools.com',
                  algo: 'equihash',
                  coin: 'zcash',
                  pool: 'windfall',
                  poolName: 'Windfall',
                  fee: '0.5'
                }}
                />*/}
                            </tbody>
                        </table>
                    </div>
                    <small className="d-block text-right mt-3">
                        <Link to="/addminingpool" title="Add mining pool">
                            Add mining pool
                        </Link>
                    </small>
                </div>
                <PoolsChart
                    option={{
                        algo: 'equihash',
                        coin: 'zcash',
                        title: 'Zcash pools chart',
                    }}
                />
            </div>
        );
    }
}

export default Zcash;
