class mining {
    static getReadableHashRateString(hashrate, algo = 'equihash') {
        let byteUnits = [' H/s', ' Kh/s', ' Mh/s', ' Gh/s', ' Th/s', ' Ph/s'];

        if (algo === 'etchash') {
            hashrate = hashrate * 1000000;
        }
        if (algo === 'equihash') {
            hashrate = hashrate * 2;
            byteUnits = [' Sol/s', ' KSol/s', ' MSol/s', ' GSol/s', ' TSol/s', ' PSol/s'];
        }
        if (hashrate < 1000000) {
            return (Math.round(hashrate / 1000) / 1000).toFixed(2) + byteUnits[0];
        }
        const i = Math.floor(Math.log(hashrate / 1000) / Math.log(1000) - 1);
        hashrate = hashrate / 1000 / Math.pow(1000, i + 1);
        return hashrate.toFixed(2) + byteUnits[i];
    }
    static getReadableSolsRateString(hashrate, algo = 'equihash') {
        let byteUnits = [' H/s', ' Kh/s', ' Mh/s', ' Gh/s', ' Th/s', ' Ph/s'];
        if (algo === 'equihash') {
            // hashrate = (hashrate * 2);
            byteUnits = [' Sol/s', ' KSol/s', ' MSol/s', ' GSol/s', ' TSol/s', ' PSol/s'];
        }
        if (hashrate < 1000000) {
            return (Math.round(hashrate / 1000) / 1000).toFixed(2) + ' Sol/s';
        }
        const i = Math.floor(Math.log(hashrate / 1000) / Math.log(1000) - 1);
        hashrate = hashrate / 1000 / Math.pow(1000, i + 1);
        return hashrate.toFixed(2) + byteUnits[i];
    }
    static getReadableHashRate(hashrate, algo = 'equihash', i = 1) {
        if (algo === 'equihash') {
            hashrate = hashrate * 2;
        }
        // if (hashrate < 1000000) {
        //   return (Math.round(hashrate / 1000) / 1000 ).toFixed(2);
        // }
        //const i = Math.floor((Math.log(hashrate / 1000) / Math.log(1000)) - 1);
        hashrate = hashrate / 1000 / Math.pow(1000, i);
        return Number(hashrate.toFixed(2));
    }
}
export default mining;
