import React, { Component } from 'react';
import PoolsChart from 'shared/components/poolsChart';
import PoolRow from 'shared/components/poolRow';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Coin2Fly extends Component {
    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{'Coin2Fly mining pools'}</title>
                    <meta
                        name="description"
                        content="Coin2Fly mining pools stats, mining pools Coin2Fly list online and chart"
                    />
                    <meta name="keywords" content="Coin2Fly,mining pools,neoscrypt" />
                </Helmet>
                <div className="my-3 p-3 bg-white rounded box-shadow">
                    <div className="table-responsive-md">
                        <table className="table">
                            <thead className="">
                                <tr>
                                    <th className="border-top-0" scope="col">
                                        Pool
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Hashrate
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Workers
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Fee
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Updated
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Visit
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <PoolRow
                                    option={{
                                        url: 'http://zergpool.com/api/currencies',
                                        link: 'http://zergpool.com',
                                        algo: 'neoscrypt',
                                        coin: 'CTF',
                                        pool: 'zergpool',
                                        poolName: 'Zergpool',
                                        fee: '0.5',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://fairmine.pro/api/currencies',
                                        link: 'https://fairmine.pro',
                                        algo: 'neoscrypt',
                                        coin: 'CTF',
                                        pool: 'fairmine',
                                        poolName: 'FairMine',
                                        fee: '0.7',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://miningclub.cc/api/currencies',
                                        link: 'https://miningclub.cc',
                                        algo: 'neoscrypt',
                                        coin: 'CTF',
                                        pool: 'miningclub',
                                        poolName: 'MiningClub',
                                        fee: '0.5',
                                    }}
                                />
                                {/*                <PoolRow option={{
                  url: 'http://althash.ru/api/currencies',
                  link: 'http://althash.ru',
                  algo: 'neoscrypt',
                  coin: 'CTF',
                  pool: 'althash',
                  poolName: 'Althash',
                  fee: '0.5',
                }}
                />*/}
                                <PoolRow
                                    option={{
                                        url: 'https://pool.cryptoally.net/api/currencies',
                                        link: 'https://pool.cryptoally.net',
                                        algo: 'neoscrypt',
                                        coin: 'CTF',
                                        pool: 'cryptoally',
                                        poolName: 'CryptoAlly',
                                        fee: '0.5',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://zpool.ca/api/currencies',
                                        link: 'https://zpool.ca',
                                        algo: 'neoscrypt',
                                        coin: 'CTF',
                                        pool: 'zpool',
                                        poolName: 'Zpool',
                                        fee: '1.75',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://pool.miningmachine.io/api/currencies',
                                        link: 'https://pool.miningmachine.io',
                                        algo: 'neoscrypt',
                                        coin: 'CTF',
                                        pool: 'miningmachine',
                                        poolName: 'Miningmachine',
                                        fee: '0',
                                    }}
                                />
                            </tbody>
                        </table>
                    </div>
                    <small className="d-block text-right mt-3">
                        <Link to="/addminingpool" title="Add mining pool">
                            Add mining pool
                        </Link>
                    </small>
                </div>
                <PoolsChart
                    option={{
                        algo: 'neoscrypt',
                        coin: 'CTF',
                        coinname: 'Coin2Fly',
                        title: 'Coin2Fly pools chart',
                    }}
                />
            </div>
        );
    }
}

export default Coin2Fly;
