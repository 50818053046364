// @flow
import * as React from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import AppRoute from './AppRoute';
import Layout from './layout';
import HomePage from './pages/Home';
import notFoundpage from 'components/notFound';
import AddMiningPoolPage from './pages/AddMiningPool';
import BuckPage from './pages/equihash/Buck';
import HushPage from './pages/equihash/Hush';
import KomodoPage from './pages/equihash/Komodo';
import VotecoinPage from './pages/equihash/Votecoin';
import ZclassicPage from './pages/equihash/Zclassic';
import SnowgemPage from './pages/equihash//Snowgem';
import LitecoinzPage from './pages/equihash/Litecoinz';
import ZcashPage from './pages/equihash/Zcash';
import ZelcashPage from './pages/equihash/Zelcash';
import ZencashPage from './pages/equihash/Zencash';
import Coin2flyPage from './pages/neoscrypt/Coin2fly';
import EtcPage from './pages/etchash/etc';
import 'open-iconic/font/css/open-iconic-bootstrap.css';
import './App.css';

type PropsT = {
    // setLocale: (string) => {},
    // t: (string) => string,
};

class App extends React.PureComponent<PropsT> {
    // setLanguage = (e) => {
    //     this.props.setLocale(e.target.value);
    // };

    render() {
        const { t } = this.props;

        return (
            <Switch>
                <AppRoute exact path="/" layout={Layout} component={HomePage} />
                <AppRoute
                    exact
                    path="/addminingpool"
                    layout={Layout}
                    component={AddMiningPoolPage}
                />
                <AppRoute
                    exact
                    path="/equihash/buck"
                    layout={Layout}
                    component={BuckPage}
                    title="Altcoin Buck"
                    icon="buck.png"
                />
                {/*                <AppRoute
                    exact
                    path="/equihash/hush"
                    layout={Layout}
                    component={HushPage}
                    title="Altcoin Hush"
                    icon="hush.png"
                />*/}
                <AppRoute
                    exact
                    path="/equihash/komodo"
                    layout={Layout}
                    component={KomodoPage}
                    title="Altcoin Komodo"
                    icon="komodo.png"
                />
                {/*                <AppRoute
                    exact
                    path="/equihash/votecoin"
                    layout={Layout}
                    component={VotecoinPage}
                    title="Altcoin Votecoin"
                    icon="votecoin.png"
                />*/}
                <AppRoute
                    exact
                    path="/equihash/zcash"
                    layout={Layout}
                    component={ZcashPage}
                    title="Altcoin Zcash"
                    icon="zcash.png"
                />
                <AppRoute
                    exact
                    path="/equihash/zclassic"
                    layout={Layout}
                    component={ZclassicPage}
                    title="Altcoin Zclassic"
                    icon="zclassic.png"
                />
                {/*                <AppRoute
                    exact
                    path="/equihash/snowgem"
                    layout={Layout}
                    component={SnowgemPage}
                    title="Altcoin SnowGem"
                    icon="snowgem.png"
                />*/}
                <AppRoute
                    exact
                    path="/equihash/litecoinz"
                    layout={Layout}
                    component={LitecoinzPage}
                    title="Altcoin Litecoinz"
                    icon="litecoinz.png"
                />
                <AppRoute
                    exact
                    path="/equihash/zencash"
                    layout={Layout}
                    component={ZencashPage}
                    title="Altcoin Zencash"
                    icon="zencash.png"
                />
                <AppRoute
                    exact
                    path="/equihash/zelcash"
                    layout={Layout}
                    component={ZelcashPage}
                    title="Altcoin Zelcash"
                    icon="zelcash.png"
                />
                <AppRoute
                    exact
                    path="/neoscrypt/coin2fly"
                    layout={Layout}
                    component={Coin2flyPage}
                    title="Altcoin Coin2Fly"
                    icon="coin2fly.png"
                />
                <AppRoute
                    exact
                    path="/etchash/etc"
                    layout={Layout}
                    component={EtcPage}
                    title="Altcoin Ethereum classic"
                    icon="etc.png"
                />
                <Route component={notFoundpage} />
            </Switch>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        location: state.router.location,
    };
};

const mapDispatchToProps = {
    // setLocale,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(App);
