import { createSelector } from 'reselect';

// Create select functions to pick off the pieces of state we care about
const entities = (state) => state.entities;
const poolFilter = (_, { algo = '', coin = '' }) => ({ algo, coin });

const getPoolInfo = (entities, { algo, coin }) => {
    if (!(algo && coin)) {
        return {};
    }
    if (!entities[algo]) {
        return {};
    }
    if (!entities[algo][coin]) {
        return {};
    }
    return entities[algo][coin];
};

export default createSelector(entities, poolFilter, getPoolInfo);
