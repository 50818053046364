import React, { Component } from 'react';
import PoolsChart from 'shared/components/poolsChart';
import PoolRow from 'shared/components/poolRow';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Komodo extends Component {
    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{'Komodo mining pools - kmd'}</title>
                    <meta
                        name="description"
                        content="Komodo mining pools stats, mining pools Komodo list online and chart"
                    />
                    <meta name="keywords" content="Komodo,mining pools,kmd,equihash" />
                </Helmet>
                <div className="my-3 p-3 bg-white rounded box-shadow">
                    {/*<h6 class="border-bottom border-gray pb-2 mb-0">Votecoin pools stats</h6>*/}
                    <div className="table-responsive-md">
                        <table className="table">
                            <thead className="">
                                <tr>
                                    <th className="border-top-0" scope="col">
                                        Pool
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Hashrate
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Workers
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Fee
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Updated
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Visit
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/*                                <PoolRow
                                    option={{
                                        url:
                                            'https://kmd.suprnova.cc/index.php?page=api&action=public',
                                        link: 'https://kmd.suprnova.cc',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'suprnova',
                                        poolName: 'Suprnova',
                                        fee: '1',
                                        kind: 'mpos1',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://komodo.miningspeed.com/api/stats',
                                        link: 'https://komodo.miningspeed.com',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'miningspeed',
                                        poolName: 'Miningspeed',
                                        fee: '0.4',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://luckpool.org/api/stats',
                                        link: 'https://luckpool.org',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'luckpool',
                                        poolName: 'LuckPool',
                                        fee: '0.5',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'http://baikalmine.ru/api/stats',
                                        link: 'http://baikalmine.ru',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'baikalmine',
                                        poolName: 'BaikalMine',
                                        fee: '0.5',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'http://kmd.cloudpools.net/api/stats',
                  link: 'http://kmd.cloudpools.net',
                  algo: 'equihash',
                  coin: 'komodo',
                  pool: 'cloudpools',
                  poolName: 'Cloudpools',
                  fee: '0.5'
                }}
                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'http://www.komodopool.com/api/stats',
                                        link: 'http://www.komodopool.com',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'komodopool',
                                        poolName: 'Komodopool',
                                        fee: '0',
                                    }}
                                />*/}
                                <PoolRow
                                    option={{
                                        url: 'http://www.kmdmonster.com/api/stats',
                                        link: 'http://www.kmdmonster.com',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'kmdmonster',
                                        poolName: 'Kmdmonster',
                                        fee: '0.4',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://kmd.coinblockers.com/api/stats',
                                        link: 'https://kmd.coinblockers.com',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'coinblockers',
                                        poolName: 'Coinblockers',
                                        fee: '0.4',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'http://kmd.altpool.pro/api/stats',
                                        link: 'http://kmd.altpool.pro',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'altpool',
                                        poolName: 'Altpool',
                                        fee: '0.1',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://kmd.minepool.hu/api/stats',
                                        link: 'https://kmd.minepool.hu',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'minepoolhu',
                                        poolName: 'Minepool',
                                        fee: '1',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'http://equi.mining4.co.uk/api/stats',
                  link: 'http://equi.mining4.co.uk',
                  algo: 'equihash',
                  coin: 'komodo',
                  pool: 'equimining4co',
                  poolName: 'Mining4',
                  fee: '0.25'
                }}
                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://coorp.io/public_api/stats',
                                        link: 'https://coorp.io/pool/kmd',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'coorpio',
                                        poolName: 'Coorp',
                                        fee: '0',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://equigems.online/api/stats',
                                        link: 'https://equigems.online',
                                        algo: 'equihash',
                                        coin: 'komodo',
                                        pool: 'equigems',
                                        poolName: 'EquiGems',
                                        fee: '0.9',
                                    }}
                                />*/}
                            </tbody>
                        </table>
                    </div>
                    <small className="d-block text-right mt-3">
                        <Link to="/addminingpool" title="Add mining pool">
                            Add mining pool
                        </Link>
                    </small>
                </div>
                <PoolsChart
                    option={{
                        algo: 'equihash',
                        coin: 'komodo',
                        title: 'Komodo pools chart',
                    }}
                />
            </div>
        );
    }
}

export default Komodo;
