import React, { Component } from 'react';
import PoolsChart from 'shared/components/poolsChart';
import PoolRow from 'shared/components/poolRow';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class Zclassic extends Component {
    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{'Zclassic mining pools - zcl'}</title>
                    <meta
                        name="description"
                        content="Zclassic mining pools stats, mining pools Zclassic list online and chart"
                    />
                    <meta name="keywords" content="Zclassic,mining pools,zcl,equihash" />
                </Helmet>
                <div className="my-3 p-3 bg-white rounded box-shadow">
                    {/*<h6 class="border-bottom border-gray pb-2 mb-0">Votecoin pools stats</h6>*/}
                    <div className="table-responsive-md">
                        <table className="table">
                            <thead className="">
                                <tr>
                                    <th className="border-top-0" scope="col">
                                        Pool
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Hashrate
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Workers
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Fee
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Updated
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Visit
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                <PoolRow
                                    option={{
                                        url:
                                            'https://zclassic.miningpoolhub.com/index.php?page=api&action=getpoolstatus&api_key=d836b8d553a54c11f70f8a65482712c281b5993d4ba12f46aeb0daa973bcfd33',
                                        link: 'https://zclassic.miningpoolhub.com',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'miningpoolhub',
                                        poolName: 'Mining pool hub',
                                        fee: '0.9',
                                        kind: 'mpos3',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url:
                                            'https://zcl.suprnova.cc/index.php?page=api&action=public',
                                        link: 'https://zcl.suprnova.cc',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'suprnova',
                                        poolName: 'Suprnova',
                                        fee: '1',
                                        kind: 'mpos1',
                                    }}
                                />*/}
                                {/* <PoolRow
                                    option={{
                                        url:
                                            'https://www2.coinmine.pl/zcl/index.php?page=api&action=public',
                                        link: 'https://www2.coinmine.pl/zcl',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'coinmine',
                                        poolName: 'Coinmine',
                                        fee: '1',
                                        kind: 'mpos2',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://zclassic.miningspeed.com/api/stats',
                                        link: 'https://zclassic.miningspeed.com',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'miningspeed',
                                        poolName: 'Miningspeed',
                                        fee: '0.4',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://luckpool.org/api/stats',
                                        link: 'https://luckpool.org',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'luckpool',
                                        poolName: 'LuckPool',
                                        fee: '0.5',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'http://zcl.pool.sexy/api2/stats',
                                        link: 'http://zcl.pool.sexy',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'poolsexy',
                                        poolName: 'Pool Sexy',
                                        fee: '0.25',
                                        kind: 'simple2',
                                    }}
                                />*/}
                                {/*  <PoolRow
                                    option={{
                                        url: 'https://zcl.2miners.com/api/stats',
                                        link: 'https://zcl.2miners.com',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: '2Minerscom',
                                        poolName: '2Miners.com',
                                        fee: '1',
                                        kind: 'simple2',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://solo-zcl.2miners.com/api/stats',
                                        link: 'https://solo-zcl.2miners.com',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: '2Minerscomsolo',
                                        poolName: '2Miners.com Solo',
                                        fee: '1.5',
                                        kind: 'simple2',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://zclmine.pro/api/stats',
                                        link: 'https://zclmine.pro',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'zclminepro',
                                        poolName: 'Zclmine',
                                        fee: '0',
                                        kind: 'simple2',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'http://baikalmine.ru/api/stats',
                                        link: 'http://baikalmine.ru',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'baikalmine',
                                        poolName: 'BaikalMine',
                                        fee: '0.5',
                                    }}
                                />*/}
                                {/* <PoolRow
                                    option={{
                                        url: 'https://equipool.1ds.us/api/stats',
                                        link: 'https://equipool.1ds.us',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: '1dsequipool',
                                        poolName: '1ds Equipool',
                                        fee: '0.2',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://minez.zone/api/stats',
                  link: 'https://minez.zone',
                  algo: 'equihash',
                  coin: 'zclassic',
                  pool: 'minezzone',
                  poolName: 'Minez.zone',
                  fee: '1'
                }}
                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://zcl.minepool.hu/api/stats',
                                        link: 'https://zcl.minepool.hu',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'minepoolhu',
                                        poolName: 'Minepool',
                                        fee: '0.4',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://lambomoon.club/api/stats',
                                        link: 'https://lambomoon.club',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'lambomoonclub',
                                        poolName: 'LamboMoon',
                                        fee: '5',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://powermining.pw/api/currencies',
                                        link: 'https://powermining.pw',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'powermining',
                                        poolName: 'Powermining',
                                        fee: '0.8',
                                        kind: 'yiimp',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'http://equi.mining4.co.uk/api/stats',
                  link: 'http://equi.mining4.co.uk',
                  algo: 'equihash',
                  coin: 'zclassic',
                  pool: 'equimining4co',
                  poolName: 'Mining4',
                  fee: '0.25'
                }}
                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url:
                                            'https://zcl.lucky-mining.com.ua/?page=api&action=public&api_key=api',
                                        link: 'https://zcl.lucky-mining.com.ua',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'luckymining',
                                        poolName: 'Lucky-Mining',
                                        fee: '0',
                                        kind: 'mpos4',
                                    }}
                                />*/}
                                {/*    <PoolRow
                                    option={{
                                        url: 'https://zcl.poolto.be/api/stats',
                                        link: 'https://zcl.poolto.be',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'pooltobe',
                                        poolName: 'Poolto.be',
                                        fee: '0.5',
                                        kind: 'simple2',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'http://zcl.altpool.pro/api/stats',
                                        link: 'http://zcl.altpool.pro',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'altpool',
                                        poolName: 'Altpool',
                                        fee: '1',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://equigems.online/api/stats',
                                        link: 'https://equigems.online',
                                        algo: 'equihash',
                                        coin: 'zclassic',
                                        pool: 'equigems',
                                        poolName: 'EquiGems',
                                        fee: '0.9',
                                    }}
                                />*/}
                            </tbody>
                        </table>
                    </div>
                    <small className="d-block text-right mt-3">
                        <Link to="/addminingpool" title="Add mining pool">
                            Add mining pool
                        </Link>
                    </small>
                </div>
                <PoolsChart
                    option={{
                        algo: 'equihash',
                        coin: 'zclassic',
                        title: 'Zclassic pools chart',
                    }}
                />
            </div>
        );
    }
}

export default Zclassic;
