import React, { Component } from 'react';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
// import { api } from 'shared/services';

class AddMiningPool extends Component {
    componentDidMount() {
        // api.fetchPool({
        //     url: 'http://votecoin.pearpool.com/api/stats',
        //     algo: 'equihash',
        //     coin: 'votecoin',
        //     pool: 'pearpool',
        //     poolName: 'Pearpool',
        //     kind: 'znomp',
        // }).then((res) => {
        //     console.log(res);
        // });
    }

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{`Add mining pool`}</title>
                    {/*          <meta
            name="description"
            content="Votecoin mining pools stats, mining pools votecoin list online and chart"
          />*/}
                    <meta name="keywords" content="Add mining pool,mining pools,equihash" />
                </Helmet>
                <div className="my-3 p-3 bg-white rounded box-shadow">
                    <h6 className="border-bottom border-gray pb-2 mb-4">Add mining pool</h6>
                    <p>{`Currently, altcoinminingpools.com support pools with API pool stats.`}</p>
                    {/*                    <p>{`Plan to add more altcoins based on equihash algo and mining pools.`}</p>*/}
                    <p>
                        {`Contact:`}{' '}
                        <a href="https://discord.gg/TJvAuPJ" target="_blank">
                            <span className="oi oi-people" /> Discord channel
                        </a>{' '}
                        - nickname VKPM (id 8509)
                    </p>
                    <p>
                        Or just fill Google form to add mining pool{' '}
                        <a
                            href="https://docs.google.com/forms/d/e/1FAIpQLSdxwgCADmySR1IwJEZxyU5Q8r4wiOYSHgKRphGGaP4_Vku3wg/viewform?usp=sf_link"
                            target="_blank"
                        >
                            link to form
                        </a>
                        .
                    </p>
                    {/*                    <p>For pools we supported JSON format (with multiple coins):</p>*/}
                    {/*                    <pre style={{ color: 'crimson' }}>
                        <code>
                            {`{
  time: 1533200471,
  pools: {
    votecoin: {
      poolStats: {
        networkSols: "241751",
      },
      hashrate: 57983783.35486605,
      workerCount: 1,
    },
    zcash: {
      poolStats: {
        networkSols: "868907128",
      },
      hashrate: 2821449915.0861635,
      workerCount: 4,
    ],
    zclassic: {
      poolStats: {
        networkSols: "62529083",
      },
      hashrate: 0,
      workerCount: 0,
    },
    zelcash: {
     ...
    },
    zen: {
     ...
    },
    snowgem {
     ...
    },
    litecoinz: {
     ...
    },
    hush: {
     ...
    },
    buck: {
     ...
    },
    komodo: {
     ...
    }
  }
}`}
                        </code>
                    </pre>*/}
                    {/*                    <ul>
                        <li>time - when stats is updated (unix timestamp in UTC)</li>
                        <li>networkSols - Total network hashrate</li>
                        <li>hashrate - Total pool hashrate</li>
                        <li>workerCount - Count of pool workers</li>
                    </ul>
                    <p>
                        Hashrate 57983783.35486605 is 115.97 Sol/s (57983783.35486605 * 2 / 1000 /
                        1000 for Sol/s)
                    </p>
                    <p>
                        By default we support Z-Nomp mining pools with api stats /api/stats. Contact
                        with me for any others formats.
                    </p>*/}
                </div>
            </div>
        );
    }
}

export default AddMiningPool;
