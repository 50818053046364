import React, { Component } from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import mining from 'shared/utils/mining';
// import 'moment-timezone';
// import PropTypes from 'prop-types';
// import {Link} from 'react-router-dom';
import getPool from 'shared/store/selectors/getPool';
import { loadPool } from 'shared/store/actions';
// moment.tz.guess()

class PoolRow extends Component {
    componentWillMount() {
        const { props } = this;
        const { url, algo, coin, pool, poolName, kind = 'znomp' } = props.option;
        props.loadPool({ url, algo, coin, pool, poolName, kind });
    }

    componentDidMount() {
        // $("[rel=tooltip]").tooltip({ placement: 'right'});
    }

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentDidUpdate() {
        $('[rel=tooltip]').tooltip({ placement: 'top' });
    }

    render() {
        const { props } = this;
        const { link, poolName, fee, pool, algo } = props.option;
        const { poolStats } = props;
        if (poolStats) {
            const { hashrate, workerCount, time } = poolStats;
            return (
                <tr>
                    {/*<span class="oi oi-badge" rel="tooltip" title="Recommended" />*/}
                    {/*<span class="oi oi-dollar" rel="tooltip" title="Sponsor of altcoinminingpools" />*/}
                    <td scope="row">
                        <If condition={pool === 'pearpool'}>
                            <span
                                className="oi oi-bolt blue-light"
                                rel="tooltip"
                                title="Our sponsor"
                            />
                        </If>
                        {poolName}
                    </td>
                    <td>{hashrate && mining.getReadableHashRateString(hashrate, algo)}</td>
                    <td>{workerCount}</td>
                    <td>{fee}%</td>
                    <td>{time && moment(time * 1000).format('D MMM HH:mm')}</td>
                    <td>
                        <a href={link} rel="noopener" target="_blank" title="Link to mining pool">
                            <span className="oi oi-external-link" />
                        </a>
                    </td>
                </tr>
            );
        }
        return null;
    }
}

PoolRow.propTypes = {};

PoolRow.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
    const { algo, coin, pool } = ownProps.option;
    return {
        poolStats: getPool(state, {
            algo,
            coin,
            pool,
        }),
    };
};

const mapDispatchToProps = {
    loadPool,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PoolRow);
