import hmacSHA256 from 'crypto-js/hmac-sha256';
import encUtf8 from 'crypto-js/enc-utf8';
import encBase64 from 'crypto-js/enc-base64';

export const base64url = (source) => {
    // Encode in classical base64
    let encodedSource = encBase64.stringify(source);

    // Remove padding equal characters
    encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
};

class jwt {
    static token = (data = {}) => {
        const header = {
            alg: 'HS256',
            typ: 'JWT',
        };
        const stringifiedHeader = encUtf8.parse(JSON.stringify(header));
        const encodedHeader = base64url(stringifiedHeader);

        const stringifiedData = encUtf8.parse(JSON.stringify(data));
        const encodedData = base64url(stringifiedData);

        let signature = encodedHeader + '.' + encodedData;
        signature = hmacSHA256(signature, 'dfUy53jFj5d23DFj2jkD7444');
        signature = base64url(signature);
        return `${encodedHeader}.${encodedData}.${signature}`;
    };
}

export default jwt;
