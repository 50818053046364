import React, { Component } from 'react';
import PoolsChart from 'shared/components/poolsChart';
import PoolRow from 'shared/components/poolRow';
import { Helmet } from 'react-helmet';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

class ZenCash extends Component {
    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return false;
    }

    render() {
        return (
            <div>
                <Helmet>
                    <title>{'ZenCash mining pools - zen'}</title>
                    <meta
                        name="description"
                        content="ZenCash mining pools stats, mining pools ZenCash list online and chart"
                    />
                    <meta name="keywords" content="ZenCash,mining pools,zen,equihash" />
                </Helmet>
                <div className="my-3 p-3 bg-white rounded box-shadow">
                    {/*<h6 class="border-bottom border-gray pb-2 mb-0">Votecoin pools stats</h6>*/}
                    <div className="table-responsive-md">
                        <table className="table">
                            <thead className="">
                                <tr>
                                    <th className="border-top-0" scope="col">
                                        Pool
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Hashrate
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Workers
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Fee
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Updated
                                    </th>
                                    <th className="border-top-0" scope="col">
                                        Visit
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {/*                                <PoolRow
                                    option={{
                                        url:
                                            'https://zen.suprnova.cc/index.php?page=api&action=getdashboarddata&api_key=e3af5bdadaf7648c76708add85b9015d6734827cb9004266904b57aa16878adb',
                                        link: 'https://zen.suprnova.cc',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'suprnova',
                                        poolName: 'Suprnova',
                                        fee: '1',
                                        kind: 'getdashboarddata2',
                                    }}
                                />*/}
                                <PoolRow
                                    option={{
                                        url:
                                            'https://zencash.miningpoolhub.com/index.php?page=api&action=getpoolstatus&api_key=d836b8d553a54c11f70f8a65482712c281b5993d4ba12f46aeb0daa973bcfd33',
                                        link: 'https://zencash.miningpoolhub.com',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'miningpoolhub',
                                        poolName: 'Mining pool hub',
                                        fee: '0.9',
                                        kind: 'mpos3',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://luckpool.org/api/stats',
                                        link: 'https://luckpool.org',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'luckpool',
                                        poolName: 'LuckPool',
                                        fee: '0.5',
                                    }}
                                />*/}
                                <PoolRow
                                    option={{
                                        url: 'https://zen.zhash.pro/api/stats',
                                        link: 'https://zen.zhash.pro',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'zhash',
                                        poolName: 'Zhash',
                                        fee: '0.5',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://zenmine.pro/api/stats',
                                        link: 'https://zenmine.pro',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'zenminepro',
                                        poolName: 'Zenmine',
                                        fee: '0',
                                        kind: 'simple2',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://zenpool.org/api/stats',
                  link: 'https://zenpool.org',
                  algo: 'equihash',
                  coin: 'zen',
                  pool: 'zenpool',
                  poolName: 'ZenPool',
                  fee: '0.5',
                }}
                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://zen.miningspeed.com/api/stats',
                                        link: 'https://zen.miningspeed.com',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'miningspeed',
                                        poolName: 'Miningspeed',
                                        fee: '0.4',
                                    }}
                                />*/}
                                <PoolRow
                                    option={{
                                        url: 'https://zen.2miners.com/api/stats',
                                        link: 'https://zen.2miners.com',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: '2Minerscom',
                                        poolName: '2Miners.com',
                                        fee: '1',
                                        kind: 'simple2',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'https://solo-zen.2miners.com/api/stats',
                                        link: 'https://solo-zen.2miners.com',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: '2Minerscomsolo',
                                        poolName: '2Miners.com Solo',
                                        fee: '1.5',
                                        kind: 'simple2',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://zen.catspool.org/api/stats',
                                        link: 'https://zen.catspool.org',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'catspool',
                                        poolName: 'Catspool',
                                        fee: '0',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://luckpool.net/zen/stats',
                                        link: 'https://luckpool.net/zen/',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'luckpool',
                                        poolName: 'LuckPool',
                                        fee: '0.5',
                                        kind: 'poolStats2',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://minez.zone/api/stats',
                  link: 'https://minez.zone',
                  algo: 'equihash',
                  coin: 'zen',
                  pool: 'minezzone',
                  poolName: 'Minez.zone',
                  fee: '1'
                }}
                />*/}
                                {/*                <PoolRow option={{
                  url: 'http://www.minermaniac.com/api/stats',
                  link: 'http://www.minermaniac.com',
                  algo: 'equihash',
                  coin: 'zen',
                  pool: 'minermaniac',
                  poolName: 'Minermaniac',
                  fee: '0.25'
                }}
                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://zen.nibirupool.com/api/stats',
                                        link: 'https://zen.nibirupool.com',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'nibirupool',
                                        poolName: 'Nibirupool',
                                        fee: '1',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'http://baikalmine.ru/api/stats',
                                        link: 'http://baikalmine.ru',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'baikalmine',
                                        poolName: 'BaikalMine',
                                        fee: '0.5',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://treefrogmines.com/api/stats',
                  link: 'https://treefrogmines.com',
                  algo: 'equihash',
                  coin: 'zen',
                  pool: 'treefrog',
                  poolName: 'TreeFrog',
                  fee: '1'
                }}
                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://powermining.pw/api/currencies',
                                        link: 'https://powermining.pw',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'powermining',
                                        poolName: 'Powermining',
                                        fee: '0.8',
                                        kind: 'yiimp',
                                    }}
                                />*/}
                                <PoolRow
                                    option={{
                                        url: 'https://equipool.1ds.us/api/stats',
                                        link: 'https://equipool.1ds.us',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: '1dsequipool',
                                        poolName: '1ds Equipool',
                                        fee: '0.2',
                                    }}
                                />
                                <PoolRow
                                    option={{
                                        url: 'http://zen.altpool.pro/api/stats',
                                        link: 'http://zen.altpool.pro',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'altpool',
                                        poolName: 'Altpool',
                                        fee: '1',
                                    }}
                                />
                                {/*                                <PoolRow
                                    option={{
                                        url:
                                            'https://zen.lucky-mining.com.ua/?page=api&action=public&api_key=api',
                                        link: 'https://zen.lucky-mining.com.ua',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'luckymining',
                                        poolName: 'Lucky-Mining',
                                        fee: '0',
                                        kind: 'mpos4',
                                    }}
                                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://myzen.cash/json/stats',
                                        link: 'https://myzen.cash/',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'myzencash',
                                        poolName: 'MyZen.Cash',
                                        fee: '0.5',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://coorp.io/public_api/stats',
                  link: 'https://coorp.io/pool/zen',
                  algo: 'equihash',
                  coin: 'zen',
                  pool: 'coorpio',
                  poolName: 'Coorp',
                  fee: '0',
                }}
                />*/}
                                {/*                                <PoolRow
                                    option={{
                                        url: 'https://equigems.online/api/stats',
                                        link: 'https://equigems.online',
                                        algo: 'equihash',
                                        coin: 'zen',
                                        pool: 'equigems',
                                        poolName: 'EquiGems',
                                        fee: '0.9',
                                    }}
                                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://zpool.wfmpools.com/api/stats',
                  link: 'https://zpool.wfmpools.com',
                  algo: 'equihash',
                  coin: 'zen',
                  pool: 'windfall',
                  poolName: 'Windfall',
                  fee: '0.5'
                }}
                />*/}
                                {/*                <PoolRow option={{
                  url: 'https://minerbay.org/api/stats',
                  link: 'https://minerbay.org',
                  algo: 'equihash',
                  coin: 'zen',
                  pool: 'minerbay',
                  poolName: 'Miner Bay',
                  fee: '12'
                }}
                />*/}
                            </tbody>
                        </table>
                    </div>
                    <small className="d-block text-right mt-3">
                        <Link to="/addminingpool" title="Add mining pool">
                            Add mining pool
                        </Link>
                    </small>
                </div>
                <PoolsChart
                    option={{
                        algo: 'equihash',
                        coin: 'zen',
                        title: 'ZenCash pools chart',
                    }}
                />
            </div>
        );
    }
}

export default ZenCash;
