import React, { Component } from 'react';
import { connect } from 'react-redux';
import getChart from 'shared/store/selectors/getChart';
import mining from 'shared/utils/mining';
import text from 'shared/utils/text';
// import PropTypes from 'prop-types';

class PoolsChart extends Component {
    constructor(props) {
        super(props);
        this.coinhashrate = 0;
    }

    componentWillMount() {
        // Client side
        if (process.browser) {
            this.update(this.props);
        }
    }

    componentDidMount() {}

    shouldComponentUpdate(nextProps, nextState) {
        return true;
    }

    componentWillUpdate(nextProps) {
        this.update(nextProps);
        // create trigger to resizeEnd event
        const resizeChart = function() {
            if (this.resizeTO) clearTimeout(this.resizeTO);
            this.resizeTO = setTimeout(function() {
                $(this).trigger('resizeEnd');
            }, 100);
        };
        $(window)
            .off('resize.chart')
            .on('resize.chart', resizeChart);

        const drawChart = this.drawChart;
        // redraw graph when window resize is completed
        $(window)
            .off('resizeEnd')
            .on('resizeEnd', () => {
                drawChart(drawChart);
            });
    }

    componentDidUpdate() {}

    update(props) {
        google.charts.load('current', { packages: ['corechart'] });

        const array = [['Pools', 'Hasrate']];

        let coinhashrate = 0;
        let lasttime = 0;
        let sum = 0;

        for (const poolId in props.chart) {
            const pool = props.chart[poolId];
            if (lasttime < pool.time && pool.poolStats && 'networkSols' in pool.poolStats) {
                lasttime = pool.time;
                coinhashrate = pool.poolStats.networkSols;
            }
            sum += pool.hashrate;
            array.push([
                pool.poolName,
                mining.getReadableHashRate(pool.hashrate, props.option.algo),
            ]);
        }
        if (coinhashrate) {
            this.coinhashrate = coinhashrate;
            let totalUnknown =
                Number(coinhashrate) - mining.getReadableHashRate(sum, props.option.algo);
            if (totalUnknown < 0) {
                totalUnknown = 0;
            }
            array.push(['Unknown pools', totalUnknown]);
        }

        this.drawChart = () => {
            const data = google.visualization.arrayToDataTable(array);
            const options = {
                // title: 'My Daily Activities',
                is3D: true,
                sliceVisibilityThreshold: 0.000001,
                chartArea: { width: '80%', height: '80%' },
            };

            const chart = new google.visualization.PieChart(document.getElementById('piechart_3d'));
            data.sort([{ column: 1, desc: true }]);
            chart.draw(data, options);
        };

        google.charts.setOnLoadCallback(this.drawChart);
    }

    render() {
        const { props } = this;
        const { algo, coin, title, coinname = '' } = props.option;

        return (
            <div className="my-3 p-3 bg-white rounded box-shadow">
                <h6 className="border-bottom border-gray pb-2 mb-1">{title}</h6>
                <p>
                    Total network hashrate:{' '}
                    {mining.getReadableSolsRateString(this.coinhashrate * 1000000, algo)}
                </p>
                <div className="embed-responsive embed-responsive-16by9">
                    <div className="embed-responsive-item" id="piechart_3d" />
                </div>
                <small className="d-block mt-3">
                    <span className="font-weight-bold">Notes</span>
                    <ul>
                        <li
                        >{`total network hashrate based on value from coin ${text.capitalizeFirstLetter(
                            coinname || coin
                        )} stats`}</li>
                        <li>{`chart hashrate based on value from pool stats`}</li>
                    </ul>
                </small>
                {/*        <small class="d-block text-right mt-3">
          <a href="#">Support us</a>
        </small>*/}
            </div>
        );
    }
}

PoolsChart.propTypes = {};

PoolsChart.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
    // const {algo, coin} = ownProps.option;
    return {
        chart: getChart(state, {
            algo: ownProps.option.algo,
            coin: ownProps.option.coin,
        }),
    };
};

export default connect(mapStateToProps)(PoolsChart);
