import { createSelector } from 'reselect';

// Create select functions to pick off the pieces of state we care about
const entities = (state) => state.entities;
const poolFilter = (_, { algo = '', coin = '', pool = '' }) => ({ algo, coin, pool });

const getPoolInfo = (entities, { algo, coin, pool }) => {
    if (!(algo && coin && pool)) {
        return undefined;
    }
    if (!entities[algo]) {
        return undefined;
    }
    if (!entities[algo][coin]) {
        return undefined;
    }
    if (!entities[algo][coin][pool]) {
        return undefined;
    }
    return entities[algo][coin][pool];
};

export default createSelector(entities, poolFilter, getPoolInfo);
